import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";

const Footer = ({ menus }) => {
  const data = useStaticQuery(graphql`
    query {
      contentfulContactDetails {
        id
        addressLine1
        addressLine2
        addressLine3
        contactNumber
        email
        facebookUrl
        twitterUrl
        instagramUrl
      }
    }
  `);

  const footerMenu =
    menus !== null && menus !== undefined
      ? menus.find(menu => menu.type === "secondary")
      : null;

  return (
    <footer id="contact" className="footer bg-tertiary text-white">
      <div className="container-footer section mx-auto py-10 footer__style">
        <div className="footer__content">
          <h3 className="text-lg font-bold mb-4 justify-center">
            Contact Info
          </h3>
          <ul className="text-sm">
            <li className="mb-2">
              <div className="item">
                <i className="item__icon material-icons text-white text-2xl">
                  business
                </i>
                <div>
                  <p className="item__text hover:text-secondary">
                    <a
                      href="https://www.google.com/maps/place/North+Shore+Commissary/@49.3194306,-123.1020564,15z/data=!4m5!3m4!1s0x0:0x60da0ec921ada28f!8m2!3d49.3194306!4d-123.1020564"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {data.contentfulContactDetails.addressLine1}
                      <br />
                      {data.contentfulContactDetails.addressLine2}
                      <br />
                      {data.contentfulContactDetails.addressLine3}
                    </a>
                  </p>
                </div>
              </div>
            </li>
            <li className="mb-2 hide">
              <div className="item">
                <i className="item__icon material-icons text-white text-2xl">
                  settings_phone
                </i>
                <div>
                  <p className="item__text hover:text-secondary">
                    <a href="tel:16047862034">
                      {data.contentfulContactDetails.contactNumber}
                    </a>
                  </p>
                </div>
              </div>
            </li>
            <li className="mb-2">
              <div className="item">
                <i className="item__icon material-icons text-white text-2xl">
                  email
                </i>
                <div>
                  <p className="item__text hover:text-secondary">
                    <a href="javascript:location='mailto:\u0072\u0079\u0061\u006e\u0040\u006e\u006f\u0072\u0074\u0068\u0073\u0068\u006f\u0072\u0065\u0063\u006f\u006d\u006d\u0069\u0073\u0073\u0061\u0072\u0079\u002e\u0063\u006f\u006d';void 0">
                      {data.contentfulContactDetails.email}
                    </a>
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        {footerMenu !== null && footerMenu !== undefined && (
          <div className="footer__content">
            <h3 className="text-lg font-bold mb-4">Menu Links</h3>
            <ul className="text-sm">
              {/* {footerMenu.menuItems.map(menu => (
                <li className="mb-2" key={menu.id}>
                  <a className="hover:text-secondary" href={menu.url}>
                    {menu.title}
                  </a>
                </li>
                 */}
              <li className="mb-2 hover:text-secondary">
                <Link to="/#home">Home</Link>
              </li>
              <li className="mb-2 hover:text-secondary">
                <Link to="/#about">About Us</Link>
              </li>
              <li className="mb-2 hover:text-secondary">
                <Link to="/#services">Services</Link>
              </li>
              <li className="mb-2 hover:text-secondary">
                <Link to="/#pricing">Pricing</Link>
              </li>
              {/* ))} */}
            </ul>
          </div>
        )}
        <div className="footer__content">
          <h3 className="text-lg font-bold mb-4">Follow Us</h3>
          <ul className="flex text-sm">
            <li className="mb-2 mr-4">
              <a
                href={data.contentfulContactDetails.facebookUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="w-6 h-6"
                  src={require("assets/images/facebook.svg")}
                  alt="Facebook Icon"
                />
              </a>
            </li>
            <li className="mb-2 mr-4">
              <a
                href={data.contentfulContactDetails.twitterUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="w-6 h-6 hide"
                  src={require("assets/images/twitter.svg")}
                  alt="Twitter Icon"
                />
              </a>
            </li>
            <li className="mb-2 mr-4">
              <a
                href={data.contentfulContactDetails.instagramUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="w-6 h-6"
                  src={require("assets/images/instagram.svg")}
                  alt="Instagram Icon"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="container-copyright">
        <div>
          <p className="footer__copyright text-xs mx-auto">
            All Rights Reserved. © Copyright {new Date().getFullYear()} North
            Shore Commissary. Built by{" "}
            <a
              className="wb underline--magical"
              href="https://www.wbdesigns.ca"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              WB Designs
            </a>
            .
          </p>
        </div>
        <div>
          <a
            className="text-xs mx-auto footer__copyright hover:text-secondary"
            href="/privacy-policy"
          >
            Privacy Policy
          </a>
          <a
            className="text-xs mx-auto footer__copyright hover:text-secondary"
            href="/terms-of-use"
          >
            Terms of Use
          </a>
        </div>
      </div>
    </footer>
  );
};

Footer.defaultProps = {
  menus: null,
};

Footer.propTypes = {
  menus: PropTypes.any,
};

export default Footer;
